import Link from 'next/link';

export default function Custom404() {
  return (
    <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='max-w-lg text-center'>
        <p className='text-base font-semibold text-HighlightDarkest'>404</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
          Oops! It seems our AI has gone rogue...
        </h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          Just kidding! But we&apos;ve hit a 404 glitch in the matrix. Our genius bots are busy crunching numbers and
          predicting the future, but they couldn&apos;t predict this missing page.
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            href='/'
            className='rounded-md bg-HighlightDarkest px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-HighlightMedium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-HighlightDarkest'>
            Go back home
          </Link>
          <Link href='https://help.levity.ai/' className='text-sm font-semibold text-gray-900'>
            Contact support <span aria-hidden='true'>&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
